import React, { useState, useEffect } from "react";

import Fab from '@material-ui/core/Fab';
import CloudDownload from '@material-ui/icons/CloudDownload';

//import { Device } from '@capacitor/device';

import { fabStyle } from "./style";

const DlFab = ({ onClick, installState, children }) => {

    const [fabHidden, setFabHidden] = useState(true);
    useEffect(() => {
        if (installState === 'installable')
            setFabHidden(false);
        else
            setFabHidden(true);
    }, [setFabHidden, installState]);

    return <>
    { fabHidden === false &&
        <Fab style={fabStyle} aria-label={'Download'} color={'primary'} onClick={onClick}>
            <CloudDownload />
            {children}
        </Fab>
    }
    </>
}

export default DlFab;