import React, { useMemo, useCallback, useState } from "react";
import _ from "lodash";
import Player from '../Player';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

import { navigate } from "gatsby-link";

import { getEntityUrl } from "../../util";

const Imageplayer = ({ component, entity, files, pageContext, ...props }) => {
    const [images, timestamps] = useMemo(() => {
        const images = {};
        _.each(component.image, 
            ({ image, timestamp }) => {
                const key = parseInt(timestamp||0);
            
                images[key] = { 
                    localFile: _.find(files.nodes, { id: image?.localFile___NODE }),
                    timestamp: key
                }
            });
        
        const sortedKeys = Object.keys(images).sort((a,b) => a-b);

        return [images, sortedKeys];
    }, [files, component]);
    
    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);
    

    const [currentTimestamp, setCurTimeIndex] = useState(0);
    const onProgress = useCallback(({ playedSeconds }) => {
        const playedMs = parseInt(playedSeconds*1000);
        
        const selTime = timestamps.reduce((prev, curr) => {
            return playedMs > curr ? curr : prev
        });

        setCurTimeIndex(timestamps.findIndex(e => e===selTime));
    }, [setCurTimeIndex, timestamps]);

    const carouselProps = {
        autoPlay: false,
        navButtonsProps: { style: { display: 'none' }},
        indicators: false,
        index: currentTimestamp,
    };
    
    const { defaultLocale } = pageContext;
    const onEnded = () => {
        if (component.continueTo) navigate(getEntityUrl({ entity: component.continueTo, defaultLocale}));
    }

    return <> {
        timestamps.length > 0 && (
        timestamps.length === 1 ?
            <GatsbyImage image={getImage(images[timestamps[0]].localFile)} alt="" />
            :
            <Carousel {...carouselProps} >
            {timestamps.map(k => 
                <Box align="center" key={k}>
                    <GatsbyImage image={getImage(images[k].localFile)} alt="" style={{ width: '100%' }} />
                </Box>
            )}
            </Carousel>
        )}
        {audioUrl !== undefined &&
        <Player style={{paddingTop:12}} width='100%' height='42px'
            url={audioUrl}
            controls={true}
            loop={component.loop || false}
            playing={component.autoplay || false}
            playsinline={component.fullscreen || false}
            onProgress={onProgress}
            onEnded={onEnded}
        />
        }
    </>
}

export default Imageplayer;
