import React from "react";
import _ from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";

import "./css/app.css";

import MainMenu from "./menu";

import Topbar from "./topbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    bildbutton: {
        display: 'block',
        position: 'absolute',
        /* font-family: 'openlight', */
        bottom: '12px',
        left: '10px',
        whiteSpace: 'nowrap',
        padding: '8px 15px',
        background: 'rgba(86, 86, 86, 0.3)',
        fontSize: '16px',
        color: '#ffffff!important',
        border: '1px solid #FFF',
        letterSpacing: '0.06em',
        textDecoration: 'none!important',
        textTransform: 'uppercase',
    },
    bHeadline: {
        position: 'absolute',
        bottom: '62px',
        left: '11px',
        /* webkitTransform: 'translateX(-50%)', */
        /* transform: 'translateX(-50%)', */
        /*whiteSpace: 'nowrap',*/
        width: 'fit-content',
        margin: '0',
        /* text-align: 'center', */
        fontSize: '22px',
        color: '#ffffff !important',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textShadow: '2px 2px 3px hsl(0deg 0% 0% / 48%)',
        fontFamily: 'opensans',
        letterSpacing: '0',
        lineHeight: '1.1',
    }
}));

const EntityPage = (props) => {
    
    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;

    const classes=useStyles();
    return selectedModule !== 'imagelist' ? 
        <></>
        : <Wrapper {...props} topbar={Topbar} title={entity.title} transparentAppbar={true}>
            {/*<MainMenu {...props} />*/}
            <div class="" style={{marginTop: '-56px', overflowX: 'hidden'}/*TODO - var*/ }>
                {children.map((child,n) => {

                    if (child.smallImage) {
                        const childImg = getImage(child.smallImage.localFile);

                        const listRole = _.find(child.Roles, { strapi_component: 'role.list-child' });
                        const overlays = getOverlayImages(listRole, files);

                        const title1 = listRole?.line1 || child.title || '';
                        const title2 = listRole?.line2 || false;

                        const { defaultLocale } = pageContext;
                        const url = getEntityUrl({ entity: child, defaultLocale });

                        return <Link to={url}>
                            <BgImage image={childImg} style={{ height: n===0 ? "61vh":"37vh", flexGrow: 0, backgroundSize: "cover" }}>
                                {overlays?.map(o => o)/* TODO - test*/}
                                { title2 !== false && 
                                <div className={classes.bHeadline}>{title2}</div>}

                                <div className={classes.bildbutton}>{title1}</div>
                            </BgImage>
                        </Link>
                    } else {
                        return <></>;
                    }
                })}
            </div>
        </Wrapper>
}

export default EntityPage;