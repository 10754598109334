import React, { useMemo } from "react";
import _ from "lodash";
import Player from '../Player';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

const Imageslider = ({ component, entity, files, ...props }) => {
    const images = useMemo(() => {
        const images = [];
        _.each(component.images, i => images.push(_.find(files.nodes, { id: i.localFile___NODE })));
        return images;
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);
    
    component.duration = parseInt(component.duration);
    const carouselProps = { //TODO - discuss
        stopAutoPlayOnHover: true,
        autoPlay: component.duration !== 0,
        interval:  (component.duration || 3)*1000,
        //animation: 'slide', //default is fade - discuss
        indicatorContainerProps: {
            style: {
                marginTop: '4px',
            }
        },
    };

    return <>{
        images.length > 0 && (
        images.length === 1 ?
            <GatsbyImage image={getImage(images[0])} alt=""/>
            :
            <Carousel {...carouselProps} >
            {images.map((i,n) => 
                <Box key={i.id} align="center">
                    <GatsbyImage image={getImage(i)} style={{ width: '100%' }} alt="" />
                    {component.images[n].caption !== '' &&
                    <div>{component.images[n].caption}</div>}
                </Box>
            )}
            </Carousel>
        )}
        {audioUrl !== undefined &&
        <Player style={{paddingTop:12}} width='100%' height='42px'
            url={audioUrl}
            controls={true}
            loop={true /*TODO - discuss*/}
            playing={component.autoplayAudio || false}
            playsinline={component.fullscreen || false}
        />
        }
    </>
}

export default Imageslider;
