import React from "react";
import _ from "lodash";

import { Card, CardContent} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./header";
import Wrapper from "../../components/Wrapper";
import Videoplayer from "../../components/Videoplayer/";
import Imageslider from "../../components/Imageslider";
import Imageplayer from "../../components/Imageplayer";
import Text from "../../components/Text";

import EntityList from "../../components/List";

const useStyles = makeStyles((theme) => ({
    root: {
        //padding: theme.spacing(1)
    },
    inline: {
        display: "inline",
    },
    card: { width: "100%", margin: theme.spacing(1, 0) },
    cardTop: { width: "100%", height: 300 },

    media: {
        width: "100%",
        height: "100%",
    },
    padding: {
        padding: theme.spacing(1)
    }
}));

const EntityPage = (props) => {
    const { entity, config, selectedModule } = props;
    
    const classes = useStyles();

    return selectedModule !== 'material' ? 
        <></>
        :
        <Wrapper {...props} title={entity.title /*TODO - discuss/define*/}>
            <div className={classes.root}>
                <Header entity={entity} />
                { 
                    entity.Components.map(component => {
                        switch (component.strapi_component) {
                            case 'component.videoplayer':
                                return <Videoplayer component={component} entity={entity} {...props} />
                                
                            case 'component.imageslider':
                                return <Imageslider component={component} entity={entity} {...props} /> 
                                
                            case 'component.imageplayer':
                                return <Imageplayer component={component} entity={entity} {...props} />
                                
                            case 'component.text':
                                return <div className={classes.padding} ><Text component={component} entity={entity} {...props} /></div>
                                
                            default:
                                return <></>;
                        }
                    })
                }

                {   
                    entity.strapiChildren.length > 0 &&
                        <EntityList {...props} />
                }
            </div>
        </Wrapper>
};

export default EntityPage;
