

import React, { Fragment } from "react";
import _ from "lodash";

import { List, ListItem, ListItemText, Divider, ListItemAvatar, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import { css } from "@emotion/react"

import { getEntityUrl } from "../util";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    image: {
        width: '150px',
        marginRight: '12px',
        verticalAlign: 'middle',
    }
}));

const EntityList = ({ entity, pageContext }) => {
    const { defaultLocale } = pageContext;

    const { strapiChildren: children } = entity;

    const classes = useStyles();
    return (
        <List className={classes.root}>
            <Divider variant="inset" component="li" />

            {children.map(entity => {
                const listRole = _.find(entity.Roles, { strapi_component: 'role.list-child' });
                const title1 = listRole?.line1 || entity.title || '';
                const title2 = listRole?.line2 || false;
                //const title3 = listRole?.line3 || false;

                const image = entity.smallImage ? getImage(entity.smallImage.localFile) : null;

                return (
                    <Fragment key={entity.identifier}>
                        <Link to={getEntityUrl({ entity, defaultLocale })}
                            css={css`
                              text-decoration: none;
                              color: inherit;
                            `/*TODO - warns*/}
                        >
                            <ListItem key={entity.identifier} button>
                                <ListItemAvatar className={classes.image}>
                                    { image !== null ? 
                                    <GatsbyImage image={image} alt={title1} layout="fullWidth" /> : <></> } 
                                </ListItemAvatar>
                                <ListItemText primary={title1} secondary={<Fragment>{title2}</Fragment>} />
                            </ListItem>
                        </Link>
                        <Divider variant="inset" component="li" />
                    </Fragment>
                );
            })}
        </List>)

};

export default EntityList;
