import React, { useEffect, useState } from "react";
import { LinearProgress, Slide } from "@material-ui/core";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    blockUI: {
        position: "absolute",
        zIndex: 1010,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: '100%',
        width: '100%',
        minHeight: '2em',
        cursor: 'wait',
        overflow: 'hidden',  
        opacity: 0.5,
        filter: 'alpha(opacity=50)',
        backgroundColor: 'rgb(184, 184, 184)',
    },
    bar: {
        width: '100%',
        backgroundColor: '#fff',//TODO - global bg col
        zIndex: 65535,
        paddingTop: '18px',
    },
});

const ProgressBar = ({ progress, children, style, installing }) => {
    const classes = useStyles();
    const [showing, setShowing] = useState(false);

    useEffect(() => {
        if (installing && progress > 0 && progress < 100) //show instantly, "ease" out when done
            setShowing(true);
        else
            setTimeout(() => setShowing(false), 1000);
    }, [progress, installing]);

    return (
        <>
            <div className={showing ? classes.blockUI : ''} />
            <Slide style={style} direction="up" in={showing} >
                <div className={classes.bar}>
                    <LinearProgressWithLabel value={progress} />
                </div>
            </Slide>
        </>
    );
};

export default ProgressBar;