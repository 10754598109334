import React, { useState, useCallback } from "react";
import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import { getEntityUrl, getTranslatedTopbarEntities } from "../../util";

import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import { Link, useStaticQuery, graphql } from "gatsby";
import { Menu, MenuItem, IconButton, Slide } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#333',
        position: 'fixed',
        zIndex: 100,
        height: '100%',
        width: '100%',
        color: '#fff',
        top: '0px',
        left: '0px'
    },
    menu: {
        width: '94%',
        background: 'transparent',
        height: 'auto',
        position: 'fixed',
        right: '0',
        top: '49px',
        zIndex: '8000',
        margin: '0px 10px',
        overflow: 'auto',
        maxHeight: '90vh',
    },
    item: {
        fontSize: '17px',
        borderBottom: '1px solid #eee',
        paddingTop: '11px',
        paddingBottom: '11px',
        display: 'block',
        paddingLeft: '0px',
        color: '#ffffff',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: 65535
    },
    logo: {

    },
    closeButton: {
        flex: '0 1 auto',
        marginLeft: 'auto'

    }

}));

const MainMenu = (props) => {
    /* TODO - util this */
    const { allStrapiAppSettings: settings, allStrapiEntities: entities } = useStaticQuery(staticQuery);
    const { locale: curLocale, defaultLocale } = props.pageContext || {};
    const translatedTopbarEntites = getTranslatedTopbarEntities(settings, entities, curLocale);
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const image = settings?.nodes[0]?.logo ? getImage(settings.nodes[0].logo.localFile) : null;

    return <>{translatedTopbarEntites.length > 0 && <>
        <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu} >
            <MenuIcon />
        </IconButton>
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>

            <div className={classes.root} >
                <div style={{display:'flex'}}>
                    <IconButton edge="end" className={classes.closeButton} color="inherit" aria-label="menu" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                </div>
               
                <div className={classes.menu} onClick={handleClose}>

                    {image !== null ?
                        <div className={classes.item}><GatsbyImage className={classes.logo} image={image} alt="logo" layout="fullWidth" /></div> : <></>}

                    {translatedTopbarEntites?.map(e =>
                        <Link key={e.identifier} className={classes.item} onClick={handleClose} to={getEntityUrl({ entity: e, defaultLocale })}>
                            {e.title}
                        </Link>
                    )}
                </div></div>
        </Slide>
    </>}</>
}

export default MainMenu;

const staticQuery = graphql`
    query {
                allStrapiAppSettings {
                    nodes {
                        Topbar {
                            languageSwitch
                            entities {
                                identifier
                                title
                                locale
                            }
                        }
                        logo {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 30)
                                }
                            }
                        }
                    }
                }
                allStrapiEntities {
                    nodes {
                        identifier
                        title
                        locale
                    }
                }
            }
    `;