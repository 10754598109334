import React from "react";
import _ from "lodash";

import { graphql } from "gatsby";

import Webflow from "../modules/webflow";
import Material from "../modules/material";
import ImgList from "../modules/imagelist";
import Renoir from "../modules/renoir";

const RouterPage = ({ data, ...props }) => {
    const { strapiEntities: entity, allFile: files, allStrapiI18NLocale: { nodes: locales } } = data;
    //allFile needed here to render gatsbyImages for strapi_components 
    
    const { module } = entity;

    const selectedModule = module?.name;
    
    const topbarConfig = _.find(entity.Components, { strapi_component: 'component.topbar' });
    
    const viewProps = {
        entity,
        selectedModule,
        files,
        locales,
        config: topbarConfig || {},
        showBackbutton: entity.identifier!=='root',
    }

    return <>
        <ImgList  {...props} {...viewProps} />
        <Renoir  {...props} {...viewProps} />
        <Webflow {...props} {...viewProps} />
        <Material {...props} {...viewProps} />
    </>;
};

export default RouterPage;

export const query = graphql`
    query ($entityId: String!, $locale: String!) {
        strapiEntities(identifier: {eq: $entityId}, locale: {eq: $locale}) {
            identifier
            title
            locale
            localizations { locale }
            shortDesc
            smallImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 150)
                    }
                }
            }
            largeImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            strapiChildren {
                title
                identifier
                locale
                smallImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 500)
                        }
                    }
                }
                Roles
            }
            Components
            Roles
            module {
                name
            }
        }
        allFile {
            nodes {
                id
                name
                publicURL
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        allStrapiI18NLocale {
            nodes {
                name
                code
                isDefault
            }
        }
        
    }
`;




/*TODO - conditional module-loading confuses webpack/gatsby-offline
class RouterPage extends React.Component {
    state = {
        Module: null,
    };

    componentDidMount = async () => {
        if (this.props.data.strapiEntities.Module?.length) {
            const module = await import(`../modules/${this.props.data.strapiEntities.Module[0]?.strapi_component.split('.')[1]}`);
            this.setState({ Module: module.default });
        }
    };

    render = () => {
        const { Module } = this.state;
        let entity = this.props.data.strapiEntities;
        entity.config = JSON.parse(entity.configJSON);//TODO - remove if remains unused

        if (!entity.Module || !entity.Module[0]?.strapi_component) return <>No module selected.</>;
        else 
            return (Module === null ? <></> : <Module showBackbutton={entity.identifier!=='root'} entity={entity} config={entity.Module[0] || {}} />);

    }
}*/
