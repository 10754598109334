import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";

import { navigate } from "gatsby";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";

import { PlayArrow as PlayArrowIcon, Clear as ClearIcon } from '@material-ui/icons';

import theme from '../theme';

import { getEntityUrl } from "../util";

const defaultProps = {
    //bgcolor: 'background.paper',
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "5rem", height: "5rem" },
};

const useStyles = makeStyles((theme) => ({
    box: {
        //padding: theme.spacing(3, 2),
        // height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // "&:hover": { backgroundColor: "red"},
        "&:active": { backgroundColor: "green" },
    },
    grid: {
        maxWidth: 450,
    },
    root: {
        //flexGrow: 1
    }
}));

const EntityKeypad = ({ entity, pageContext }) => {
    const { defaultLocale }= pageContext;
    
    const entities = useMemo(() => {
        const entities = {};
        entity.strapiChildren.forEach(({ Roles, identifier, title: caption, locale }) => {
            const listRole = _.find(Roles, { strapi_component: 'role.list-child' });
            const numCode = parseInt(listRole?.numCode||0);
            if (numCode) entities[numCode] = { identifier, caption, locale };
        });
        return entities;
    }, [entity]);

    let buttons = [...Array(10).keys()];
    buttons.push('clr')
    buttons.push(buttons.shift());
    buttons.push('play');

    const [keyEntered, setKeyEntered] = useState(' ');
    const [playEnabled, setPlayEnabled] = useState(false);

    const buttonClicked = (key) => {

        switch (key) {
            case 'clr':
                setKeyEntered('');
                setPlayEnabled(false);
                break;
            case 'play':
                navigate(getEntityUrl({ entity: entities[parseInt(keyEntered)], defaultLocale }));
                break;
            default:
                setKeyEntered(keyEntered + key);
        }
    };

    useEffect(() => {
        const checkEntry = () => {
            let entry = parseInt(keyEntered);
            if (entities[entry])
                setPlayEnabled(true);
            else
                setPlayEnabled(false);
        };

        let timer;
        if (keyEntered.length >= 3) {//TODO - hardcoded 3
            timer = setTimeout(checkEntry, 500);
        }
        return () => {
            if (timer) { clearTimeout(timer); timer = null; }
        };
    }, [keyEntered, entities]);//TODO - move entities-creation to useMemo, react sez

    const classes = useStyles();
    return (
        <div className={classes.root} align="center">
            <Grid container className={classes.grid} spacing={1} justifyContent="center">
                <Grid item xs={12} align="center">
                    <Box {...defaultProps} m={0} style={{ height: '5rem' }} bgcolor="lightgray" display="flex" alignItems="center" justifyContent="center">
                        <Grid container><Grid item xs={12}><Typography variant="h4" >{keyEntered}</Typography></Grid>
                            <Grid item xs={12}><Typography variant="caption" >{playEnabled && entities[parseInt(keyEntered)]?.caption}</Typography></Grid></Grid>
                    </Box>
                </Grid>

                {buttons.map((item) => {
                    //item = ++item;
                    return (
                        <Grid key={item} item xs={4} align="center">
                            <Box onClick={() => buttonClicked(item)} className={classes.box} borderRadius="50%" {...defaultProps} >
                                <KeypadButton {...{ playEnabled, item }} />
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

const KeypadButton = ({ item, playEnabled }) => {
    switch (item) {
        case 'clr':
            return <h4><ClearIcon color="error" style={{ fontSize: theme.typography.h4.fontSize }} /></h4>;
        case 'play':
            return <h4><PlayArrowIcon disabled={!playEnabled} style={{ color: playEnabled ? 'green' : 'lightgray', fontSize: theme.typography.h4.fontSize /*TODO - should be action color forom theme */ }} /></h4>;
        default:
            return <Typography variant="h4" component="p">{item}</Typography>;//TODO - make button?
    }
};

export default EntityKeypad;
