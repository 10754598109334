import * as React from "react";

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    CardMedia,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { getImage, GatsbyImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({/* TODO - dup - move to layout/theme */
    card: { width: "100%", margin: theme.spacing(1,0) },
    cardTop: { width: "100%", height: 300 },

    media: {
        width: "100%",
        height: "100%",
    },
}));

const Header = ({ entity }) => {
    const classes = useStyles();

    const headerTitle = (entity.shortDesc||'').replace(/(?:\r\n|\r|\n)/g, '<br />') || false;

    return  <>
        { entity.largeImage !== null && 
            <Card className={classes.cardTop}>
                {/* <CardHeader title="" subheader="" />*/}
                <CardMedia xs={12} className={classes.media} >
                    <GatsbyImage image={getImage(entity.largeImage.localFile)} alt="" />
                </CardMedia>
                <CardContent>
                    <Typography></Typography>
                </CardContent>
            </Card>
        }

        {headerTitle !== false && 
        <Card className={classes.card}>
            <CardHeader title={<span dangerouslySetInnerHTML={{ __html: headerTitle }} />} align="center" />
        </Card>}
    </>
}

export default Header;