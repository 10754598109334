import React, {useEffect} from "react"

import Layout from "./Layout"
import Seo from "./Seo";//TODO - set title below

import 'typeface-roboto'

import { App } from "@capacitor/app";

import Pwa from './Pwa/';

const Wrapper = ({ children, ...props }) => {
    useEffect(() => {
        App.addListener('backButton', () => {
            window.history.back();

        });
    });

    return (
        <Layout {...props}>
            <Pwa {...props} />
            <Seo title="Home" />
            {children}
        </Layout>
    )
}


export default Wrapper