import React, { useMemo } from "react";
import _ from "lodash";

import Player from '../Player';
import { navigate } from "gatsby-link";

import { getEntityUrl } from "../../util";

const Videoplayer = ({ component, entity, files, pageContext, ...props }) => {
    const { defaultLocale } = pageContext;

    const videoUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.video?.localFile___NODE })?.publicURL;
    }, [files, component]);
    
    const onEnded = () => {
        if (component.continueTo) navigate(getEntityUrl({ entity: component.continueTo, defaultLocale}));
    }

    return <>{
        videoUrl !== undefined &&
        <Player width='100%' height='100%'
            url={videoUrl}
            controls={true}
            stopOnUnmount={false}
            playing={component.autoplay || false}
            playsinline={component.fullscreen || false}
            onEnded={onEnded}
        />
    }
    </>
};

export default Videoplayer;