import React from 'react'
import { getImage, GatsbyImage } from "gatsby-plugin-image";
//import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles";
import IndexView from '../views/IndexView'

const useStyles = makeStyles((theme) => ({
    img: {
        display: 'block',
    },
}));

const IndexController = ({ entity, ...props }) => {
    const classes = useStyles();
    const children = entity.strapiChildren;
    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc||' ').replace(/(?:\r\n|\r|\n)/g, '<br />');

    return <IndexView>
        <headline>{headline}</headline>

        {image !== null && <header-image>
            <GatsbyImage className={classes.img} image={image} alt={headline} />
        </header-image>
        }
        
        {children.map(child => {return <list-item-view key={child.identifier} entity={child} {...props} />})}
    </IndexView>
}

export default IndexController;