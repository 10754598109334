import React from "react";
import ReactPlayer from 'react-player';

const Player = (props) => {

    const config = {
        file: {
            attributes: {
                controlsList: 'nodownload noplaybackrate',
            }
        }
    };
    const onProgress = () => {
        //TODO - statistics
    }

    return <ReactPlayer width='100%' height='100%'
            config={config}
            onProgress={onProgress}
            {...props}
    />;
}

export default Player;
