import React from "react";
import { navigate } from "gatsby";

import { Typography, AppBar, Toolbar, IconButton } from "@material-ui/core";

import DialpadIcon from '@material-ui/icons/Dialpad';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ListIcon from '@material-ui/icons/List';

import LanguageSelector from "../../components/LanguageSelector";

import useStyles from "../../components/styles/menu";

import _ from "lodash";

import Menu from "./menu"

const TopBar = ({ children, keypadSwitch, onKeypad, keypadShown,
    showBackbutton = true, title = "Root", transparentAppbar = false, ...props }) => {

    /* TODO - app - move to scrollwrapper - hide menu on scroll
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        //TODO: use useScrollTrigger https://material-ui.com/components/app-bar/#hide-app-bar
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);

            setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);*/
    /* --- */

    const classes = useStyles();
    const hideBackButton = showBackbutton ? {} : { style: { display: 'none' } };//TODO - useEffect/State to be correct

    const barStyle = { style: { boxShadow: 'none' } };
    if (transparentAppbar) barStyle.style.backgroundColor = 'transparent';
    return (
        <>
            {/*<Grow in={!scrolling}> - TODO - cms - should be configurable, may super-modular dynamic-zone setting... */}
            <AppBar className={classes.appBar} {...barStyle} >
                <Toolbar className={classes.toolbar}>

                    <IconButton
                        {...hideBackButton}
                        edge="start"
                        color="inherit"
                        aria-label="backbutton"
                        onClick={() => { navigate(-1); }}
                    >
                        <ArrowBackIcon />
                    </IconButton>


                    <div className={classes.rightItems}>
                        <LanguageSelector {...props} />

                        {keypadSwitch &&
                            <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="keypad" onClick={onKeypad} >
                                {keypadShown ? <ListIcon /> : <DialpadIcon />}
                            </IconButton>
                        }

                        <Menu {...props} />

                    </div>
                </Toolbar>
            </AppBar>
            {/*</Grow>*/}
            <div className={classes.toolbarMargin} />
        </>
    );
}

export default TopBar;
