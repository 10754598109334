import React, { Fragment, useState } from "react";
import _ from "lodash";
import Collapsible from 'react-collapsible';

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { getEntityUrl } from "../../util";

import Menu from "../imagelist/menu";
import EntityKeypad from "../../components/Keypad";

import { makeStyles } from "@material-ui/core/styles";

import Wrapper from "../../components/Wrapper";
import Videoplayer from "../../components/Videoplayer/";
import Imageslider from "../../components/Imageslider";
import Imageplayer from "../../components/Imageplayer";
import Text from "../../components/Text";

import List from "./list";

import "./richtext.css"
import "./collapsible.css"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0',
        textDecoration: 'none',
        fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif;',
    },

    headline: {
        padding: '14px 8px',
        fontSize: '20px',
        lineHeight: '15px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#000',
        backgroundColor: '#fff',
    },
    padding: {
        padding: theme.spacing(1)
    },


    
}));

const EntityPage = (props) => {
    const { entity, config, selectedModule, pageContext } = props;

    const classes = useStyles();

    const { defaultLocale } = pageContext;

    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc || ' ').replace(/(?:\r\n|\r|\n)/g, '<br />');



    const [keypadShown, setKeypadShown] = useState(false);//TODO - survive reload, check cookbook ch3 - redux-reducer, gatsby redux example, gatsby-redux-plugin
    const keypadToggle = () => {
        setKeypadShown(!keypadShown);
    };
    return selectedModule !== 'renoir' ?
        <></>
        :
        <Wrapper {...props} menu={Menu} title={entity.title} keypadSwitch={config.showKeypad || false} keypadShown={keypadShown} onKeypad={keypadToggle}>
            {keypadShown ?
                <EntityKeypad {...{ entity }} {...props} /> :

                <div className={classes.root}>
                    {image !== null &&
                        <>
                            <GatsbyImage className={classes.img} image={image} alt={headline} layout="fullWidth" />
                            <div className={classes.headline}>{headline}</div>
                        </>
                    }
                    {
                        entity.Components.map((component, n) => {
                            const Wrap = component.collapsible ?
                                ({ children, component }) => 
                                    <Collapsible className={classes.collapsible} trigger={component.label||'Click here'} open={!component.collapsed}>
                                        {children}
                                    </Collapsible> 
                                    
                                :

                                ({ children }) => <>{children}</>

                            return <Wrap key={`${component.strapi_component}${n}`} component={component}>{(() => {
                                switch (component.strapi_component) {
                                    case 'component.videoplayer':
                                        return <Videoplayer component={component} entity={entity} {...props} />

                                    case 'component.imageslider':
                                        return <>
                                            <Imageslider component={component} entity={entity} {...props} />
                                            {(n === 0 && image === null) ? <div className={classes.headline}>{headline}</div> : <></>
                                                /*TODO - ugly */
                                            }
                                        </>

                                    case 'component.imageplayer':
                                        return <Imageplayer component={component} entity={entity} {...props} />

                                    case 'component.text':
                                        return <div className={classes.padding} ><Text component={component} entity={entity} {...props} /></div>


                                    default:
                                        return <></>;
                                }
                            })()}
                            </Wrap>
                        })
                    }



                    <List {...props} />
                </div>
            }
        </Wrapper>
};

export default EntityPage;
